<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="checkinoutData"
      item-key="id"
      sort-by="name"
      :search="buscar"
      :header-props="headerProps"
      :footer-props="footerProps"
      :loading="loadingClients"
      loading-text="Cargando Fichadas..."
      no-data-text="No hay registros para mostrar."
      no-results-text="No se encontraron registros en la búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="buscar"
            append-icon="mdi-magnify"
            label="Buscar por nombre / dni / email / etc."
            outlined
            dense
          ></v-text-field>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  mdiPencil, mdiDeleteEmpty, mdiAccountPlus, mdiEraser, mdiFaceWoman, mdiFaceMan,
} from '@mdi/js'

import axios from 'axios'

export default {
  props: {
    rol: {
      type: String,
      required: true,
      default: 'Cobrador',
    },
  },
  data: () => ({
    icons: {
      mdiPencil,
      mdiDeleteEmpty,
      mdiAccountPlus,
      mdiEraser,
      mdiFaceWoman,
      mdiFaceMan,
    },
    headers: [
      {
        text: 'DNI',
        align: 'start',
        sortable: true,
        value: 'dni',
      },
      // eslint-disable-next-line no-useless-concat
      { text: 'Empleado', value: 'employee' },
      { text: 'Fecha y Hora', value: 'fechaHora' },
    ],
    headerProps: {
      sortByText: 'Ordenar por',
    },
    footerProps: {
      itemsPerPageText: 'Registros por página',
      pageText: '{0}-{1} de {2}',
      itemsPerPageOptions: [5, 10, 20, 50, 100, { text: 'Todos', value: -1 }],
    },
    checkinoutData: [],
    buscar: '',
    loadingClients: false,
    mensajeSnack: '',
    employeePersonId: null,
  }),
  mounted() {
    if (this.rol !== 'Administrador' && this.rol !== 'Gerente' && this.rol !== 'Encargado') { this.getCheckInOutListOfEmployee() } else { this.getCheckInOutList() }
  },
  methods: {
    async getCheckInOutList() {
      this.url = `${this.$store.getters.urlBase}/api/Report/checkinout`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',

        },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.checkinoutData = respuesta.data
        this.loadingClients = false
        // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;
      }
    },
    async getCheckInOutListOfEmployee() {
      this.url = `${this.$store.getters.urlBase}/api/Report/checkinoutByPersonId`
      const idperson = parseInt(this.$store.getters.usuario.user_personid)
      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { personId: idperson },
        url: this.url,
      }
      this.loadingClients = true
      const respuesta = await axios(this.url, options)
      if (respuesta.status === 200) {
        this.checkinoutData = respuesta.data
        this.loadingClients = false

        // this.getListarDiasMantenimiento();
      } else {
        this.loadingClients = false

        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;
      }
    },
  },
}
</script>
