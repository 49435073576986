<template>
    <v-row>
      <!-- basic -->
      <v-col cols="12">
        <v-card>
          <v-card-title>Listado de Fichada</v-card-title>
          <DataTableCheckInOut :rol="rolUserLogged"></DataTableCheckInOut>
        </v-card>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import DataTableCheckInOut from './DataTableCheckinout.vue'
  
  export default {
    components: {  
        DataTableCheckInOut,  
    },
    data: () => ({
      rolUserLogged: null,
    }),
    created() {
      this.rolUserLogged = this.$store.getters.usuario.user_rol
    },
    
  }
  </script>
  